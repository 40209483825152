import { Card, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Rest from "../../../libraries/Rest";
import Alert from "../../../libraries/Alert";
import * as Components from "../../../components/Components";
import LocalStorage from "../../../libraries/LocalStorage";

function EmployeeForm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const rest = new Rest();
    const storage = new LocalStorage();

    const roleName = storage.getItem('role_name');
    const isCreate = id?.toLowerCase() === 'add' ? true : false;

    /**
     * Init states
     */
    const rootPath = `/employees`;
    const endpoint = `/employees`;

    let [payload, setPayload] = useState({
        account: {
            company_id: null,
            email: null,
            password: null
        },
        general: {
            nik: null,
            first_name: null,
            middle_name: null,
            last_name: null,
            gender: null,
            birth_place: null,
            birth_date: null,
            marital_status: null,
            religion: null,
            blood_type: null,
            nationality: null,
            current_address: null,
            identity_address: null,
            phone: null,
            emergency_phone: null,
            emergency_name: null,
            emergency_relation: null,
            join_date: null,
            join_reference: null,
            join_reference_employee: null,
            remark: null,
            is_active: null,
        },
        documents: {
            identity_card_number: null,
            tax_id_number: null,
            license_number: null,
            salary_type: null,
            pph_type: null,
            ptkp: null,
            bpjs_occupation_number: null,
            bpjs_occupation_join_date: null,
            bpjs_health_number: null,
            bpjs_health_join_date: null,
            assurance_type: 'AT1', // static default
            assurance_number: null
        },
        position: {
            department_id: null,
            position_id: null,
            employee_type_id: null,
            start_date: null,
            end_date: null,
            remark: null,
        },
        bank: {
            bank_id: null,
            bank_branch: null,
            bank_city: null,
            account_number: null,
            account_name: null,
            remark: null,
        }
    });
    let [genders, setGenders] = useState([]);
    let [maritalStatuses, setMaritalStatuses] = useState([]);
    let [religions, setReligions] = useState([]);
    let [nationalities, setNationalities] = useState([]);
    let [bloodTypes, setBloodTypes] = useState([]);
    let [salaryTypes, setSalaryTypes] = useState([]);
    let [pphTypes, setPphTypes] = useState([]);
    let [ptkp, setPtkp] = useState([]);
    let [departments, setDepartments] = useState([]);
    let [positions, setPositions] = useState([]);
    let [employeeTypes, setEmployeeTypes] = useState([]);
    let [companies, setCompanies] = useState([]);
    let [banks, setBanks] = useState([]);

    /**
     * Global functions
     */
    const generateOptions = (data, value, label) => {
        let options = [];
        for (let i = 0; i < data.length; i++) {
            options.push({
                value: data[i][value] !== undefined ? data[i][value] : null,
                label: data[i][label] !== undefined ? data[i][label] : null
            });
        }
        return options;
    }

    const __findValue = (data, dataKey, key) => {
        let value = null;
        if (data && data?.length === undefined) {
            value = data[key] !== undefined ? data[key] : null;
        } else if (data) {
            for (let i = 0; i < data.length; i++) {
                if (data[i][key] !== undefined) {
                    /**
                     * Find value in object
                     */
                    value = data[i][key];
                    break;
                }
            }
        }

        /**
         * Send response
         */
        return value;
    }

    /**
     * First init function
     */
    const getDataById = async () => {
        const response = await rest.get(`${endpoint}/${id}`);
        if (response?.data?.data !== undefined) {
            const data = response.data.data;

            let updatedPayload = {};
            for (let i = 0; i < Object.keys(payload).length; i++) {
                const parentKey = Object.keys(payload)[i];
                let childPayload = {};
                for (let j = 0; j < Object.keys(payload[parentKey]).length; j++) {
                    const childKey = Object.keys(payload[parentKey])[j];

                    /**
                     * Find in response of service
                     */
                    let value = null;
                    for (let k = 0; k < Object.keys(data).length; k++) {
                        value = __findValue(data[Object.keys(data)[k]], Object.keys(data)[k], childKey);
                        if (value) break; // stop process when got the value
                    }

                    /**
                     * Append to payload
                     */
                    if (typeof value === 'boolean') value = value ? 'true' : 'false';
                    childPayload[childKey] = value;
                }
                updatedPayload[parentKey] = childPayload;
            }

            /**
             * Load necessary data as default
             */
            if (updatedPayload.account.company_id) {
                const companyId = updatedPayload.account.company_id;

                await getDepartments(companyId);
                await getBanks(companyId);
                await getEmployeeTypes(companyId);

                if (updatedPayload.position.department_id) await getPositions(updatedPayload.position.department_id, companyId);
            }

            /**
             * [UpdateDataWithStaticWay]
             * To prevent some errors further, I want to just use static way to update specific data
             */
            if (updatedPayload?.bank?.remark !== undefined) updatedPayload.bank.remark = data?.employee_banks[0]?.remark;
            if (updatedPayload?.position?.remark !== undefined) updatedPayload.position.remark = data?.job_histories[0]?.remark;

            /**
             * Update payload
             */
            setPayload(updatedPayload);
        } else {
            Alert.showMessage('Error', 'error', 'Data is not found');
            navigate(rootPath);
        }
    }

    const getStaticDatas = async () => {
        const response = await rest.get(`/statics`);
        if (response?.data?.data) {
            const data = response.data.data;
            for (let i = 0; i < Object.keys(data).length; i++) {
                const key = Object.keys(data)[i]?.toLowerCase();
                if (key === 'gender') setGenders(generateOptions(data[key], 'value', 'label_en'));
                if (key === 'marital_status') setMaritalStatuses(generateOptions(data[key], 'value', 'label_en'));
                if (key === 'nationality') setNationalities(generateOptions(data[key], 'value', 'label_en'));
                if (key === 'religion') setReligions(generateOptions(data[key], 'value', 'label_en'));
                if (key === 'blood_type') setBloodTypes(generateOptions(data[key], 'value', 'label_en'));
                if (key === 'salary_type') setSalaryTypes(generateOptions(data[key], 'value', 'label_en'));
                if (key === 'pph_type') setPphTypes(generateOptions(data[key], 'value', 'label_en'));
                if (key === 'ptkp') setPtkp(generateOptions(data[key], 'value', 'label_en'));
            }
        }
    }

    const getCompanies = async () => {
        const response = await rest.get(`/companies?page=1&limit=100`);
        if (response?.data?.data?.data) {
            let data = [];
            let companyName = storage.getItem('company_name');

            for (let i = 0; i < response.data.data.data.length; i++) {
                let v = response.data.data.data[i];
                let push = true;
                if (roleName !== 'super_admin') {
                    push = v?.name?.toLowerCase() === companyName?.toLowerCase() ? true : false;
                    if (push) {
                        setPayload({
                            ...payload, account: {
                                ...payload.account,
                                company_id: v.id
                            }
                        });
                        setPositions([]);
                        await getDepartments(v.id);
                        await getBanks(v.id);
                        await getEmployeeTypes(v.id);
                    }
                }
                if (push) data.push(v);
            }
            setCompanies(generateOptions(data, 'id', 'name'));
        }
    }

    const getDepartments = async (companyId = null) => {
        const response = await rest.get(`/companies/departments/${companyId}?page=1&limit=100`);
        if (response?.data?.data?.data) {
            const data = response.data.data.data;
            setDepartments(generateOptions(data, 'id', 'name'));

            /**
             * Check if department_id is already setted [case: edit page]
             */
            if (payload.position.department_id) getPositions(payload.position.department_id, companyId);
        }
    }

    const getPositions = async (departmentId = null, companyId = null) => {
        if (!companyId) companyId = payload.account.company_id;
        const response = await rest.get(`/companies/positions/${companyId}?page=1&limit=100&department_id=${departmentId}`);
        if (response?.data?.data?.data) {
            const data = response.data.data.data;
            setPositions(generateOptions(data, 'id', 'name'));
        }
    }

    const getBanks = async (companyId = null) => {
        const response = await rest.get(`/companies/banks/${companyId}?page=1&limit=100`);
        if (response?.data?.data?.data) {
            const data = response.data.data.data;
            setBanks(generateOptions(data, 'id', 'description'));
        }
    }

    const getEmployeeTypes = async (companyId = null) => {
        const response = await rest.get(`/companies/employee_types/${companyId}?page=1&limit=100`);
        if (response?.data?.data?.data) {
            const data = response.data.data.data;
            setEmployeeTypes(generateOptions(data, 'id', 'name'));
        }
    }

    useEffect(() => {
        const __init = async () => {
            Alert.showLoading();
            await getStaticDatas();
            await getCompanies();
            if (!isCreate) await getDataById();
            Alert.close();
        }
        __init();
    }, []);


    /**
     * Action handlers
     */
    const updatePayload = async (field, value) => {

        const arrField = field.split('.');
        if (arrField.length === 2) {
            field = arrField[1];
            setPayload({ ...payload, [arrField[0]]: { ...payload[arrField[0]], [field]: value } });
        } else {
            setPayload({ ...payload, [field]: value });
        }

        /**
         * Call positions when department has changed
         * So positions will load with department_id filter
         */
        if (field === 'company_id') {
            Alert.showLoading();
            /**
             * Reset positions
             */
            setPositions([]);

            await getDepartments(value);
            await getBanks(value);
            await getEmployeeTypes(value);
            Alert.close();
        } else if (field === 'department_id') {
            Alert.showLoading();
            await getPositions(value);
            Alert.close();
        }
    }

    const submit = async () => {

        /**
         * Validate required input
         */
        let requireField = [
            'account.email', 'account.company_id',
            'position.department_id', 'position.position_id', 'position.employee_type_id',
            'general.nik', 'general.first_name',
            'documents.ptkp'
        ];
        if (isCreate) requireField.push('account.password'); // validate password if form type is 'create'

        let errMessages = ``;
        for (let i = 0; i < requireField.length; i++) {
            const arrField = requireField[i].split('.');

            /**
             * Use multi level check to prevent error
             */
            if (payload[arrField[0]] !== undefined) {
                if (payload[arrField[0]][arrField[1]] !== undefined) {
                    const value = payload[arrField[0]][arrField[1]];
                    if (value === '' || value === null) errMessages += `Field ${arrField[1]} cannot be empty!<br />`;
                }
            }

        }
        if (errMessages !== '') return Alert.showMessage('Warning', 'warning', errMessages);

        /**
         * Update few datas
         */
        payload.general.is_active = payload.general.is_active === 'true' ? true : false;

        /**
         * Show confirm dialog before send to service
         */
        Alert.showConfirm({
            url: endpoint + (!isCreate ? `/${id}` : ``),
            method: isCreate ? 'POST' : 'PUT',
            data: payload,
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    navigate(rootPath);
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.message);
                }
            }
        });
    }

    /**
     * Rendering DOM
     */
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                    <ol className="breadcrumb fs-sm mb-1">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={rootPath}>List companies</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Form create
                        </li>
                    </ol>
                </div>
            </div>

            <Row className="g-3">
                <Col xl="12">
                    <Card className="card">
                        <Card.Header className="bg-primary">
                            <Card.Title
                                as="label"
                                className="fs-sm fw-medium mb-1 text-white"
                            >
                                Form create employee
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="bg-white">
                            <Link to={rootPath} className="btn btn-sm btn-warning text-white">
                                <i className="ri-arrow-left-line"></i> Back
                            </Link>
                            &nbsp;
                            <button className="btn btn-sm btn-primary" onClick={submit}>
                                <i className="ri-check-line"></i> Save
                            </button>
                            <br /><br />
                            <Row className="g-3">
                                <Col sm="5">
                                    <legend>
                                        <i className="ri-user-line"></i> Account
                                    </legend>
                                    <Components.Input
                                        type="text"
                                        name="account.email"
                                        label="Email*"
                                        placeholder="Email"
                                        defaultValue={payload.account.email}
                                        change={updatePayload}
                                    />
                                    <Components.Input
                                        type="password"
                                        name="account.password"
                                        label="Password"
                                        placeholder="Password"
                                        defaultValue={payload.account.password}
                                        change={updatePayload}
                                    />
                                    <Components.Select
                                        name="account.company_id"
                                        label="Company*"
                                        defaultValue={payload.account.company_id}
                                        change={updatePayload}
                                        // disabled={roleName === 'super_admin' ? false : true}
                                        noInitOption={roleName === 'super_admin' ? false : true}
                                        options={companies}
                                    />
                                </Col>
                                <Col sm="7">
                                    <legend>
                                        <i className="ri-medal-line"></i> Position
                                    </legend>
                                    <Row>
                                        <Col sm="6">
                                            <Components.Select
                                                name="position.department_id"
                                                label="Department*"
                                                defaultValue={payload.position.department_id}
                                                change={updatePayload}
                                                options={departments}
                                            />
                                        </Col>
                                        <Col sm="6">
                                            <Components.Select
                                                name="position.position_id"
                                                label="Position*"
                                                defaultValue={payload.position.position_id}
                                                change={updatePayload}
                                                options={positions}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <Components.Select
                                                name="position.employee_type_id"
                                                label="Employee Type*"
                                                defaultValue={payload.position.employee_type_id}
                                                change={updatePayload}
                                                options={employeeTypes}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Components.Input
                                                type="date"
                                                name="position.start_date"
                                                label="Start Date*"
                                                defaultValue={payload.position.start_date}
                                                change={updatePayload}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Components.Input
                                                type="date"
                                                name="position.end_date"
                                                label="End Date"
                                                defaultValue={payload.position.end_date}
                                                change={updatePayload}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <Components.Input
                                                type="text"
                                                name="position.remark"
                                                label="Remark"
                                                placeholder="Remark.."
                                                defaultValue={payload.position.remark}
                                                change={updatePayload}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="g-3">
                                <Col sm="12">
                                    <legend>
                                        <i className="ri-pencil-line"></i> General Information
                                    </legend>
                                    <Components.Input
                                        type="text"
                                        name="general.nik"
                                        label="NIK*"
                                        placeholder="NIK"
                                        defaultValue={payload.general.nik}
                                        change={updatePayload}
                                    />
                                    <Row>
                                        <Col sm="4">
                                            <Components.Input
                                                type="text"
                                                name="general.first_name"
                                                label="First Name*"
                                                placeholder="First name.."
                                                defaultValue={payload.general.first_name}
                                                change={updatePayload}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Components.Input
                                                type="text"
                                                name="general.middle_name"
                                                label="Middle Name"
                                                placeholder="Middle name.."
                                                defaultValue={payload.general.middle_name}
                                                change={updatePayload}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Components.Input
                                                type="text"
                                                name="general.last_name"
                                                label="Last Name"
                                                placeholder="Last name.."
                                                defaultValue={payload.general.last_name}
                                                change={updatePayload}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="8">
                                            <Components.Input
                                                type="text"
                                                name="general.birth_place"
                                                label="Birth place"
                                                placeholder="Birth place.."
                                                defaultValue={payload.general.birth_place}
                                                change={updatePayload}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Components.Input
                                                type="date"
                                                name="general.birth_date"
                                                label="Birth date"
                                                defaultValue={payload.general.birth_date}
                                                change={updatePayload}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <Components.Select
                                                name="general.gender"
                                                label="Gender"
                                                defaultValue={payload.general.gender}
                                                change={updatePayload}
                                                options={genders}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Components.Select
                                                name="general.marital_status"
                                                label="Marital Status"
                                                defaultValue={payload.general.marital_status}
                                                change={updatePayload}
                                                options={maritalStatuses}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Components.Select
                                                name="general.religion"
                                                label="Religion"
                                                defaultValue={payload.general.religion}
                                                change={updatePayload}
                                                options={religions}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <Components.Select
                                                name="general.blood_type"
                                                label="Blood Type"
                                                defaultValue={payload.general.blood_type}
                                                change={updatePayload}
                                                options={bloodTypes}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Components.Select
                                                name="general.nationality"
                                                label="Nationality"
                                                defaultValue={payload.general.nationality}
                                                change={updatePayload}
                                                options={nationalities}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Components.Select
                                                name="general.is_active"
                                                label="Status Active"
                                                defaultValue={payload.general.is_active}
                                                change={updatePayload}
                                                options={[
                                                    {
                                                        value: 'false',
                                                        label: 'Not Active'
                                                    }, {
                                                        value: 'true',
                                                        label: 'Active'
                                                    }
                                                ]}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            <Components.Input
                                                type="text"
                                                name="general.phone"
                                                label="Phone"
                                                placeholder="Phone.."
                                                defaultValue={payload.general.phone}
                                                change={updatePayload}
                                            />
                                        </Col>
                                        <Col sm="6">
                                            <Components.Input
                                                type="text"
                                                name="general.emergency_phone"
                                                label="Emergency Phone"
                                                placeholder="Emergency Phone.."
                                                defaultValue={payload.general.emergency_phone}
                                                change={updatePayload}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            <Components.Input
                                                type="text"
                                                name="general.emergency_name"
                                                label="Emergency Name"
                                                placeholder="e.g Mr. Jon Doe"
                                                defaultValue={payload.general.emergency_name}
                                                change={updatePayload}
                                            />
                                        </Col>
                                        <Col sm="6">
                                            <Components.Input
                                                type="text"
                                                name="general.emergency_relation"
                                                label="Emergency Relation"
                                                placeholder="e.g Father"
                                                defaultValue={payload.general.emergency_relation}
                                                change={updatePayload}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <Components.Input
                                                type="date"
                                                name="general.join_date"
                                                label="Join Date"
                                                defaultValue={payload.general.join_date}
                                                change={updatePayload}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Components.Input
                                                type="text"
                                                name="general.join_reference"
                                                label="Join Reference"
                                                placeholder="Join Reference.."
                                                defaultValue={payload.general.join_reference}
                                                change={updatePayload}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Components.Input
                                                type="text"
                                                name="general.remark"
                                                label="Remark"
                                                placeholder="Remark.."
                                                defaultValue={payload.general.remark}
                                                change={updatePayload}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <Components.Input
                                                type="text"
                                                name="general.current_address"
                                                label="Current Address"
                                                placeholder="Current address.."
                                                defaultValue={payload.general.current_address}
                                                change={updatePayload}
                                            />
                                        </Col>
                                        <Col sm="12">
                                            <Components.Input
                                                type="text"
                                                name="general.identity_address"
                                                label="Identity Address"
                                                placeholder="Identity address.."
                                                defaultValue={payload.general.identity_address}
                                                change={updatePayload}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="g-3">
                                <Col sm="6">
                                    <legend>
                                        <i className="ri-file-line"></i> Documents
                                    </legend>
                                    <Row>
                                        <Col sm="12">
                                            <Components.Input
                                                type="text"
                                                name="documents.identity_card_number"
                                                label="KTP*"
                                                placeholder="KTP.."
                                                defaultValue={payload.documents.identity_card_number}
                                                change={updatePayload}
                                            />
                                        </Col>
                                        <Col sm="12">
                                            <Components.Input
                                                type="text"
                                                name="documents.tax_id_number"
                                                label="NPWP*"
                                                placeholder="NPWP.."
                                                defaultValue={payload.documents.tax_id_number}
                                                change={updatePayload}
                                            />
                                        </Col>
                                        <Col sm="12">
                                            <Components.Input
                                                type="text"
                                                name="documents.license_number"
                                                label="SIM"
                                                placeholder="SIM.."
                                                defaultValue={payload.documents.license_number}
                                                change={updatePayload}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <Components.Select
                                                name="documents.salary_type"
                                                label="Salary Type"
                                                defaultValue={payload.documents.salary_type}
                                                change={updatePayload}
                                                options={salaryTypes}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Components.Select
                                                name="documents.pph_type"
                                                label="PPH Type"
                                                defaultValue={payload.documents.pph_type}
                                                change={updatePayload}
                                                options={pphTypes}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Components.Select
                                                name="documents.ptkp"
                                                label="PTKP*"
                                                defaultValue={payload.documents.ptkp}
                                                change={updatePayload}
                                                options={ptkp}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            <Components.Input
                                                type="text"
                                                name="documents.bpjs_occupation_number"
                                                label="BPJS Tenaga Kerja"
                                                defaultValue={payload.documents.bpjs_occupation_number}
                                                change={updatePayload}
                                            />
                                        </Col>
                                        <Col sm="6">
                                            <Components.Input
                                                type="date"
                                                name="documents.bpjs_occupation_join_date"
                                                label="Registration Date"
                                                defaultValue={payload.documents.bpjs_occupation_join_date}
                                                change={updatePayload}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            <Components.Input
                                                type="text"
                                                name="documents.bpjs_health_number"
                                                label="BPJS Kesehatan"
                                                defaultValue={payload.documents.bpjs_health_number}
                                                change={updatePayload}
                                            />
                                        </Col>
                                        <Col sm="6">
                                            <Components.Input
                                                type="date"
                                                name="documents.bpjs_health_join_date"
                                                label="Registration Date"
                                                defaultValue={payload.documents.bpjs_health_join_date}
                                                change={updatePayload}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            <Components.Select
                                                name="documents.assurance_type"
                                                label="Assurance Type"
                                                defaultValue={payload.documents.assurance_type}
                                                change={updatePayload}
                                                options={[
                                                    {
                                                        value: 'AT1',
                                                        label: '-- None --'
                                                    }, {
                                                        value: 'AT2',
                                                        label: 'BPKB'
                                                    }, {
                                                        value: 'AT3',
                                                        label: 'IJAZAH'
                                                    }
                                                ]}
                                                noInitOption={true}
                                            />
                                        </Col>
                                        {payload.documents.assurance_type !== 'AT1' && <Col sm="6">
                                            <Components.Input
                                                type="text"
                                                name="documents.assurance_number"
                                                label="Assurance Number"
                                                defaultValue={payload.documents.assurance_number}
                                                change={updatePayload}
                                            />
                                        </Col>}
                                    </Row>
                                </Col>
                                <Col sm="6">
                                    <legend>
                                        <i className="ri-bank-line"></i> Bank Account
                                    </legend>
                                    <Row>
                                        <Col sm="4">
                                            <Components.Select
                                                name="bank.bank_id"
                                                label="Bank*"
                                                defaultValue={payload.bank.bank_id}
                                                change={updatePayload}
                                                options={banks}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Components.Input
                                                type="text"
                                                name="bank.bank_branch"
                                                label="Branch Office"
                                                defaultValue={payload.bank.bank_branch}
                                                change={updatePayload}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Components.Input
                                                type="text"
                                                name="bank.bank_city"
                                                label="Bank City"
                                                defaultValue={payload.bank.bank_city}
                                                change={updatePayload}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            <Components.Input
                                                type="text"
                                                name="bank.account_name"
                                                label="Account Name*"
                                                defaultValue={payload.bank.account_name}
                                                change={updatePayload}
                                            />
                                        </Col>
                                        <Col sm="6">
                                            <Components.Input
                                                type="text"
                                                name="bank.account_number"
                                                label="Account Number*"
                                                defaultValue={payload.bank.account_number}
                                                change={updatePayload}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <Components.Input
                                                type="text"
                                                name="bank.remark"
                                                label="Remark"
                                                defaultValue={payload.bank.remark}
                                                change={updatePayload}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default EmployeeForm;