import { Card, Col, Row, Modal, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import numeral from "numeral";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import Rest from "../../libraries/Rest";
import Alert from "../../libraries/Alert";
import * as Components from "../../components/Components";

function PayrollDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const rest = new Rest();

    /**
     * Init states
     */
    const rootPath = `/payrolls`;
    const endpoint = `/payrolls`;

    const [payload, setPayload] = useState();

    /**
     * State for modal items [_tmp]
     * Items always updated by employee selected
     * 
     * @var array _tempItems
    */
    const [_tempItems, _setTempItems] = useState([]);
    const [showModalItem, setModalItem] = useState(false);
    const openModalItem = (type) => setModalItem(type);

    /**
     * First init
     */
    const getDataById = async () => {
        Alert.showLoading();
        const response = await rest.get(`${endpoint}/${id}`);
        Alert.close();

        if (response?.data?.data !== undefined) {
            const data = response.data.data;
            setPayload(data);
        } else {
            Alert.showMessage('Warning', 'warning', 'Data is not found');
            navigate(rootPath);
        }
    }

    const openDetail = (row) => {
        if (payload?.employees[row] !== undefined) {
            _setTempItems(payload.employees[row]);
            openModalItem(true);
        }
    }

    useEffect(() => {
        async function __init() {
            await getDataById();
        }
        __init();
    }, []);

    /**
     * Action handlers
     */
    const exportToBca = async () => {
        /**
         * Get data from service
         */
        Alert.showLoading();
        const response = await rest.get(`/payrolls/export_bca/${payload?.id}`);
        Alert.close();

        if (response?.data?.data === undefined) return Alert.showMessage('Warning', 'warning', 'Data for export to BCA is not found');

        /**
         * Defined sheet properties
         */
        let data = response.data.data;
        let header = [
            "No",
            "Transaction ID",
            "Transfer Type",
            "Beneficiary ID",
            "Credited Acc.",
            "Receiver Name",
            "Amount",
            "NIP",
            "Remark",
            "Beneficiary Email",
            "Receiver Bank Cd",
            "Receiver Cust. Type",
            "Receiver Cust. Residen"
        ];

        /**
         * XLSX configurations
         */
        const ws = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [header]);
        XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        /**
         * Generate file
         */
        const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array', cellStyles: true });
        const finalData = new Blob([excelBuffer], { type: 'application/octet-stream' });

        /**
         * Download file automatically
         */
        saveAs(finalData, "Template-payroll-BCA.csv");
    }

    /**
     * Rendering DOM
     */
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                    <ol className="breadcrumb fs-sm mb-1">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={rootPath}>List payrolls</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Detail payroll
                        </li>
                    </ol>
                </div>
            </div>

            <Row className="g-3">
                <Col xl="12">
                    {/* Modl detail items:per-employee */}
                    <Modal show={showModalItem} onHide={() => openModalItem(false)} size="xl">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Detail component items
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="table table-condensed table-hovered">
                                <thead>
                                    <tr>
                                        <th>Component</th>
                                        <th>Type</th>
                                        <th>Total</th>
                                        <th>Description</th>
                                        <th>Remark</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_tempItems?.salary_details !== undefined && _tempItems.salary_details.map((v, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{v?.name}</td>
                                                <td>{v?.type_text}</td>
                                                <td>{numeral(v?.value).format('0,0')}</td>
                                                <td>{v?.description}</td>
                                                <td>{v?.remark}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => openModalItem(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Form payroll */}
                    <Card className="card">
                        <Card.Header className="bg-primary">
                            <Card.Title
                                as="label"
                                className="fs-sm fw-medium mb-1 text-white"
                            >
                                Detail payroll
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="bg-white">
                            {payload?.status?.toUpperCase() === 'S3' && <div className="alert alert-danger"><strong>Canceled!</strong>, this payroll already canceled by admin</div>}
                            <Link to={rootPath} className="btn btn-sm btn-warning text-white">
                                <i className="ri-arrow-left-line"></i> Back
                            </Link>
                            &nbsp;
                            {payload?.status?.toUpperCase() === 'S2' && <button type="button" onClick={exportToBca} className="btn btn-sm btn-success text-white">
                                <i className="ri-file-excel-line"></i> Export to BCA Template
                            </button>}
                            <br /><br />
                            <Row>
                                <Col sm="4">
                                    <Components.Input
                                        name="company_id"
                                        label="Company"
                                        disabled={true}
                                        defaultValue={payload?.companies?.name}
                                    />
                                </Col>
                                <Col sm="2">
                                    <Components.Input
                                        name="start_date"
                                        label="Start Date"
                                        disabled={true}
                                        defaultValue={payload?.start_date}
                                    />
                                </Col>
                                <Col sm="2">
                                    <Components.Input
                                        name="end_date"
                                        label="End Date"
                                        disabled={true}
                                        defaultValue={payload?.end_date}
                                    />
                                </Col>
                                <Col sm="2">
                                    <Components.Input
                                        name="status"
                                        label="Status"
                                        disabled={true}
                                        defaultValue={payload?.status_text}
                                    />
                                </Col>
                                <Col sm="2">
                                    <Components.Input
                                        name="type"
                                        label="Type"
                                        disabled={true}
                                        defaultValue={payload?.type_text}
                                    />
                                </Col>
                                <Col sm="12">
                                    <Components.Input
                                        name="remark"
                                        label="Remark"
                                        disabled={true}
                                        defaultValue={payload?.remark}
                                    />
                                </Col>
                            </Row>
                            <hr />
                            <legend>List Employees</legend>
                            <table className="table table-condensed">
                                <thead>
                                    <tr>
                                        <th>NIK</th>
                                        <th>Employee</th>
                                        <th>Gross Salary</th>
                                        <th>Total Allowances</th>
                                        <th>Total Deductions</th>
                                        <th>Total Taxes</th>
                                        <th>Nett Salary</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {payload?.employees !== undefined && payload.employees.map((v, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{v?.employees?.nik}</td>
                                                <td>{v?.employees?.first_name} {v?.employees?.middle_name ?? ''} {v?.employees?.last_name ?? ''}</td>
                                                <td>{numeral(v?.gross_salary).format('0,0')}</td>
                                                <td>{numeral(v?.total_allowances).format('0,0')}</td>
                                                <td>{numeral(v?.total_deductions).format('0,0')}</td>
                                                <td>{numeral(v?.total_taxes).format('0,0')}</td>
                                                <td>{numeral(v?.nett_salary).format('0,0')}</td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-primary"
                                                        onClick={() => openDetail(i)}
                                                    >
                                                        Detail
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default PayrollDetail;