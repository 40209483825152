import { Card, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Grid, _ } from "gridjs-react";
import { useEffect, useState } from "react";
import Rest from "./../../libraries/Rest";
import LocalStorage from "./../../libraries/LocalStorage";
import Alert from "./../../libraries/Alert";

import * as Components from "./../../components/Components";

function LeaveList() {
    const rest = new Rest();
    const localStorage = new LocalStorage();
    const roleName = localStorage.getItem('role_name');
    const allowedRole = 'super_admin'; // static role validation (code: VL01)

    const navigate = useNavigate();
    const [companies, setCompanies] = useState([]);
    const [payloads, setPayloads] = useState({
        companyId: null,
        type: null
    });

    const endpoint = `/leaves`;
    const rootPath = `/leaves`;

    const getCompanies = async () => {
        let res = await rest.get(`/companies?page=1&limit=100`);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;

            let options = [];
            for (let i = 0; i < res.length; i++) {
                options.push({
                    value: res[i]['id'] !== undefined ? res[i]['id'] : null,
                    label: res[i]['name'] !== undefined ? res[i]['name'] : null,
                });
            }
            setCompanies(options);
        }
    }

    const updatePayload = async (field, value) => {
        setPayloads({ ...payloads, [field]: value });
    }

    useEffect(() => {
        if (roleName?.toLowerCase() === allowedRole) getCompanies(); // static role validation (code: VL01)
    }, []);

    const openPage = (id, type = 'detail') => {
        let url = type === 'detail' ? `${rootPath}/detail` : rootPath;
        navigate(`${url}/${id}`);
    }

    const removeItem = async (id) => {
        Alert.showConfirm({
            url: `${endpoint}/${id}`,
            method: 'DELETE',
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    navigate(`${rootPath}`)
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.message);
                }
            }
        });
    }

    return (
        <div>
            <Card className="bg-white">
                <Card.Header className="bg-primary">
                    <Card.Title as="label" className="fs-sm fw-medium mb-1 text-white">
                        List of Leaves
                    </Card.Title>
                    <Link to={`${rootPath}/add`} className="btn btn-warning btn-sm" style={{ float: 'right' }}>
                        <i className="ri-add-line"></i> Create Leave
                    </Link>
                </Card.Header>
                <Card.Body className="bg-white">
                    <Row>
                        {roleName?.toLowerCase() === allowedRole && <Col sm="3">
                            <Components.Select
                                label="Company"
                                name="companyId"
                                defaultValue={payloads.companyId}
                                change={updatePayload}
                                options={companies}
                            />
                        </Col>}
                        <Col sm="3">
                            <Components.Select
                                label="Type"
                                name="type"
                                defaultValue={payloads.type}
                                change={updatePayload}
                                options={[
                                    {
                                        label: 'Leave',
                                        value: 'T1'
                                    }, {
                                        label: 'Permit',
                                        value: 'T2'
                                    }
                                ]}
                            />
                        </Col>
                    </Row>
                    <Grid
                        className={{ table: 'table table-bordered mb-0' }}
                        resizable={true}
                        language={{
                            search: {
                                placeholder: 'Find something..'
                            }
                        }}
                        columns={[
                            'No. Leave',
                            'NIK',
                            'Name',
                            'Date',
                            'Total Day(s)',
                            'Type',
                            'Status',
                            'Actions',
                        ]}
                        server={{
                            url: `${rest.MAIN_HOST}${endpoint}?def=true${payloads.companyId !== '' && payloads.companyId !== null && payloads.companyId !== undefined ? '&companyId=' + payloads.companyId : ''}${payloads.type !== '' && payloads.type !== null && payloads.type !== undefined ? '&type=' + payloads.type : ''}`,
                            headers: {
                                'authorization': `Bearer ${rest.ACCESS_TOKEN}`
                            },
                            then: data => data?.data?.data?.map(r => [
                                r.no_leave,
                                r?.employees?.nik,
                                `${r?.employees?.first_name} ${r?.employees?.middle_name} ${r?.employees?.last_name}`,
                                `${r.start_date} - ${r.end_date}`,
                                `${r?.total_days} day(s)`,
                                r?.type_text,
                                r?.status_text,
                                _(
                                    <div style={{ textAlign: 'center' }}>
                                        {r.status?.toUpperCase() === 'S1' && <button type="button" onClick={() => openPage(r.id, 'edit')} className="btn btn-success btn-sm text-white" style={{ marginRight: 5 }} title="Update data"><i className="ri-pencil-line"></i></button>}
                                        <button type="button" onClick={() => openPage(r.id, 'detail')} className="btn btn-info btn-sm text-white" title="Detail data"><i className="ri-eye-line"></i></button>
                                        &nbsp;
                                        <button type="button" onClick={() => removeItem(r.id)} className="btn btn-danger btn-sm text-white" title="Remove data"><i className="ri-delete-bin-line"></i></button>
                                    </div>
                                )
                            ]),
                            total: data => data?.data?.total?.data,
                            handle: (res) => {
                                if (res.status === 401) return rest.interceptor();
                                return res.json();
                            }
                        }}
                        search={{
                            server: {
                                url: (prev, keyword) => `${prev}?keywords=${keyword}`
                            }
                        }}
                        pagination={{
                            limit: 10,
                            server: {
                                url: (prev, page, limit) => {
                                    const operator = prev.includes('?') ? '&' : '?';
                                    if (parseInt(page) <= 0) page = 1;
                                    return `${prev}${operator}limit=${limit}&page=${page}`
                                }
                            }
                        }}
                    />
                </Card.Body>
            </Card>
        </div>
    )

}

export default LeaveList;