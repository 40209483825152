import { Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Grid, _ } from "gridjs-react";
import Rest from "./../../../libraries/Rest";
import Alert from "../../../libraries/Alert";

function UserList() {
    const rest = new Rest();
    const navigate = useNavigate();
    const endpoint = `/users`;
    const rootPath = `/users`;

    const updateItem = async (id) => {
        navigate(`${rootPath}/${id}`);
    }

    const resetDevice = async (id) => {
        Alert.showConfirm({
            url: `${endpoint}/reset_device/${id}`,
            method: 'GET',
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.data?.detail);
                    navigate(rootPath);
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.message);
                }
            }
        });
    }

    const removeItem = async (id) => {
        Alert.showConfirm({
            url: `${endpoint}/${id}`,
            method: 'DELETE',
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    navigate(rootPath);
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.message);
                }
            }
        });
    }

    return (
        <div>
            <Card className="bg-white">
                <Card.Header className="bg-primary">
                    <Card.Title as="label" className="fs-sm fw-medium mb-1 text-white">User List</Card.Title>
                </Card.Header>
                <Card.Body className="bg-white">
                    <Link to={`${rootPath}/add`} className="btn btn-primary btn-sm">
                        <i className="ri-add-line"></i> Create Data
                    </Link>
                    <br /><br />
                    <Grid
                        className={{ table: 'table table-bordered mb-0' }}
                        resizable={true}
                        language={{
                            search: {
                                placeholder: 'Find something..'
                            }
                        }}
                        columns={[
                            'NIK',
                            'Email',
                            'Phone',
                            'Role',
                            'Company',
                            'Action',
                        ]}
                        server={{
                            url: `${rest.MAIN_HOST}${endpoint}`,
                            headers: {
                                'authorization': `Bearer ${rest.ACCESS_TOKEN}`
                            },
                            then: data => data?.data?.data?.map(r => [
                                r.user_code,
                                r.email,
                                r.phone,
                                r?.role_name?.replace(/_/g, ' '),
                                r?.user_companies.length > 0 ? r?.user_companies[0]?.companies?.name : '-',
                                _(
                                    <div style={{ textAlign: 'center' }}>
                                        <button type="button" onClick={() => updateItem(r.id)} className="btn btn-info btn-sm text-white" title="Edit data"><i className="ri-pencil-line"></i></button>
                                        &nbsp;
                                        <button type="button" onClick={() => resetDevice(r.id)} className="btn btn-warning btn-sm text-white" title="Reset device"><i className="ri-refresh-line"></i></button>
                                        &nbsp;
                                        <button type="button" onClick={() => removeItem(r.id)} className="btn btn-danger btn-sm" title="Remove data"><i className="ri-delete-bin-line"></i></button>
                                    </div>
                                )
                            ]),
                            total: data => data?.data?.total?.data,
                            handle: (res) => {
                                if (res.status === 401) return rest.interceptor();
                                return res.json();
                            }
                        }}
                        search={{
                            server: {
                                url: (prev, keyword) => `${prev}?keywords=${keyword}`
                            }
                        }}
                        pagination={{
                            limit: 10,
                            server: {
                                url: (prev, page, limit) => {
                                    const operator = prev.includes('?') ? '&' : '?';
                                    if (parseInt(page) <= 0) page = 1;
                                    return `${prev}${operator}limit=${limit}&page=${page}`
                                }
                            }
                        }}
                    />
                </Card.Body>
            </Card>
        </div>
    )

}

export default UserList;