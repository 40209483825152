import { Card, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Alert from "../../libraries/Alert";
import Rest from "../../libraries/Rest";
import * as Components from "../../components/Components";

function OvertimeDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const rest = new Rest();

    const rootPath = `/overtimes`;
    const endpoint = `/overtimes`;

    const [payload, setPayload] = useState({});
    const [attachments, setAttachments] = useState([]);

    /**
     * Init first data
     */
    const getDataById = async () => {
        const response = await rest.get(`${endpoint}/${id}`);
        if (response?.data?.data !== undefined) {
            const data = response.data.data;
            setPayload(data);
            setAttachments(data?.attachments !== undefined ? data.attachments : []);
        } else {
            Alert.showMessage('Not found', 'error', 'Data is not found');
            navigate(rootPath);
        }
    }

    useEffect(() => {
        getDataById();
    }, []);

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                    <ol className="breadcrumb fs-sm mb-1">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={rootPath}>List overtimes</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Detail overtime
                        </li>
                    </ol>
                </div>
            </div>

            <Row className="g-3">
                <Col xl="12">
                    <Card className="card">
                        <Card.Header className="bg-primary">
                            <Card.Title
                                as="label"
                                className="fs-sm fw-medium mb-1 text-white"
                            >
                                Detail overtime
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="bg-white">
                            <Link to={rootPath} className="btn btn-sm btn-warning text-white">
                                <i className="ri-arrow-left-line"></i> Back
                            </Link>
                            <br /><br />

                            <Row>
                                <Col sm="8">
                                    <legend>Detail Overtime</legend>
                                    <hr />
                                    <table className="table table-condensed">
                                        <tbody>
                                            <tr>
                                                <td>No. Overtime</td>
                                                <td>: {payload?.no_overtime}</td>
                                            </tr>
                                            <tr>
                                                <td>Overtime Date</td>
                                                <td>: {payload?.overtime_date}</td>
                                            </tr>
                                            <tr>
                                                <td>Time (IN)</td>
                                                <td>: {payload?.time_start}</td>
                                            </tr>
                                            <tr>
                                                <td>Time (OUT)</td>
                                                <td>: {payload?.time_end}</td>
                                            </tr>
                                            <tr>
                                                <td>Remark</td>
                                                <td>: {payload?.remark}</td>
                                            </tr>
                                            <tr>
                                                <td>Status</td>
                                                <td>: {payload?.status_text}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                                <Col sm="4">
                                    <legend>Detail Employee</legend>
                                    <hr />
                                    <table className="table table-condensed">
                                        <tbody>
                                            <tr>
                                                <td>NIK</td>
                                                <td>: {payload?.employees?.nik}</td>
                                            </tr>
                                            <tr>
                                                <td>First Name</td>
                                                <td>: {payload?.employees?.first_name}</td>
                                            </tr>
                                            <tr>
                                                <td>Middle Name</td>
                                                <td>: {payload?.employees?.middle_name}</td>
                                            </tr>
                                            <tr>
                                                <td>Last Name</td>
                                                <td>: {payload?.employees?.last_name}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12">
                                    <legend>Attachments</legend>
                                    <hr />
                                    <Row>
                                        {attachments.map((item, index) => (
                                            <Col sm="4" key={index}>
                                                <p style={{ textAlign: 'center' }}>{item?.file_name}</p>
                                                <div style={{ textAlign: "center" }}>
                                                    <img
                                                        src={item?.file_url}
                                                        alt={item?.file_name}
                                                        className="img-thumbnail"
                                                        style={{
                                                            width: '100%',
                                                            height: '300px',
                                                            objectFit: 'contain'
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row >
        </div >
    )
}

export default OvertimeDetail;