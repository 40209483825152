import { Card, Row, Col, Modal } from "react-bootstrap";
import { Grid, _ } from "gridjs-react";
import { useEffect, useState } from "react";
import Rest from "./../../libraries/Rest";
import LocalStorage from "./../../libraries/LocalStorage";

import * as Components from "./../../components/Components";

function ReportOvertimes() {
    const rest = new Rest();
    const localStorage = new LocalStorage();
    const roleName = localStorage.getItem('role_name');
    const allowedRole = 'super_admin'; // static role validation (code: VL01)
    var endpoint = `/reports/overtimes?withAttachments=true`;

    const [companies, setCompanies] = useState([]);
    const [payload, setPayload] = useState({
        companyId: null,
        startDate: null,
        endDate: null,
        status: null
    });
    const [parameters, setParameters] = useState('');

    /**
     * State for open modal
     */
    const [attachments, setAttachments] = useState([]);
    const [showAttachment, setShowAttachment] = useState(false);
    const openAttachment = (type) => setShowAttachment(type);

    /**
     * First init
     */
    const getCompanies = async () => {
        let res = await rest.get(`/companies?page=1&limit=100`);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;

            let options = [];
            for (let i = 0; i < res.length; i++) {
                options.push({
                    value: res[i]['id'] !== undefined ? res[i]['id'] : null,
                    label: res[i]['name'] !== undefined ? res[i]['name'] : null,
                });
            }
            setCompanies(options);
        }
    }

    const updatePayload = async (field, value) => {
        setPayload({ ...payload, [field]: value });

        /**
         * Set parameters
         */
        let _parameters = parameters;
        if (_parameters.includes(field)) {
            const _parr = _parameters.split('&');
            _parameters = ''; // reset;
            const _skip = value && value !== '' ? false : true;

            for (let i = 0; i < _parr.length; i++) {
                let _varr = _parr[i].split('=');
                if (_varr.length >= 1 && _varr[0] === field) {
                    if (!_skip) {
                        _varr[1] = value;
                    } else {
                        continue; // remove this field
                    }
                }

                /**
                 * Join the parameters
                 */
                if (
                    _varr[0] && _varr[0] !== undefined
                    &&
                    _varr[1] && _varr[1] !== undefined
                ) _parameters += `&${_varr[0]}=${_varr[1]}`;
            }
        } else {
            _parameters += `&${field}=${value}`;
        }
        setParameters(_parameters);
    }

    useEffect(() => {
        if (roleName?.toLowerCase() === allowedRole) getCompanies(); // static role validation (code: VL01)
    }, []);

    const openAttachments = (_attachments = []) => {
        setAttachments(_attachments);
        openAttachment(true);
    }

    return (
        <div>
            <Card className="bg-white">
                <Card.Header className="bg-primary">
                    <Card.Title as="label" className="fs-sm fw-medium mb-1 text-white">
                        Report of overtimes
                    </Card.Title>
                </Card.Header>
                <Card.Body className="bg-white">
                    <Row className="mt-1">
                        {/* Modal attachments */}
                        <Modal show={showAttachment} onHide={() => openAttachment(false)} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>Detail Attachments</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    {attachments.map((v, i) => {
                                        return (
                                            <Col sm="6" key={i}>
                                                <img
                                                    src={v?.file_url}
                                                    className="img-thumbnail"
                                                />
                                                <p style={{ fontWeight: "bold", marginTop: 20 }}>
                                                    {v?.file_name}
                                                </p>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Modal.Body>
                        </Modal>

                        {roleName?.toLowerCase() === allowedRole &&
                            <Col sm="3">
                                <Components.Select
                                    label="Company"
                                    name="companyId"
                                    defaultValue={payload.companyId}
                                    change={updatePayload}
                                    options={companies}
                                />
                            </Col>
                        }
                        <Col sm="2">
                            <Components.Input
                                type="date"
                                label="Start Date"
                                name="startDate"
                                defaultValue={payload.startDate}
                                change={updatePayload}
                            />
                        </Col>
                        <Col sm="2">
                            <Components.Input
                                type="date"
                                label="End Date"
                                name="endDate"
                                defaultValue={payload.endDate}
                                change={updatePayload}
                            />
                        </Col>
                        <Col sm="2">
                            <Components.Select
                                label="Status"
                                name="status"
                                defaultValue={payload.status}
                                change={updatePayload}
                                options={[
                                    {
                                        label: 'Draft',
                                        value: 'S1'
                                    }, {
                                        label: 'Approved',
                                        value: 'S2'
                                    }, {
                                        label: 'Reject',
                                        value: 'S3'
                                    }
                                ]}
                            />
                        </Col>
                    </Row>
                    <Grid
                        className={{ table: 'table table-bordered mb-0' }}
                        resizable={true}
                        language={{
                            search: {
                                placeholder: 'Find something..'
                            }
                        }}
                        columns={[
                            'No. Overtime',
                            'Employee (NIK)',
                            'Company (Branch)',
                            'Overtime Date',
                            'Time Start',
                            'Time End',
                            'Total Min(s)',
                            'Status',
                            'Attachments',
                        ]}
                        server={{
                            url: `${rest.MAIN_HOST}${endpoint}${parameters}`,
                            headers: {
                                'authorization': `Bearer ${rest.ACCESS_TOKEN}`
                            },
                            then: data => data?.data?.data?.map(r => [
                                r?.no_overtime,
                                `${r?.employees?.first_name} ${r?.employees?.middle_name ?? ''} ${r?.employees?.last_name ?? ''} ${r?.employees?.nik ? '(' + r.employees.nik + ')' : ''}`,
                                `${r?.companies?.name} ${r?.company_branches?.name ? '(' + r.company_branches.name + ')' : ''}`,
                                `${r?.overtime_date}`,
                                `${r?.time_start}`,
                                `${r?.time_end}`,
                                `${r?.time_diff} Min(s)`,
                                `${r?.status_text}`,
                                _(
                                    <div style={{ textAlign: 'center' }}>
                                        <button type="button" className="btn btn-primary btn-sm" onClick={() => openAttachments(r?.attachments)}>
                                            <i className="ri-attachment-line"></i>
                                        </button>
                                    </div>
                                )
                            ]),
                            total: data => data?.data?.total?.data,
                            handle: (res) => {
                                if (res.status === 401) return rest.interceptor();
                                return res.json();
                            }
                        }}
                        search={{
                            server: {
                                url: (prev, keyword) => `${prev}?keywords=${keyword}`
                            }
                        }}
                        pagination={{
                            limit: 10,
                            server: {
                                url: (prev, page, limit) => {
                                    const operator = prev.includes('?') ? '&' : '?';
                                    if (parseInt(page) <= 0) page = 1;
                                    return `${prev}${operator}limit=${limit}&page=${page}`
                                }
                            }
                        }}
                    />
                </Card.Body>
            </Card>
        </div>
    )

}

export default ReportOvertimes;